import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ACTIONTYPES } from "../store/ActionTypes";
import axios from "axios";

const useSendEmail = () => {
  const [sent, setSent] = useState(false);
  const dispatch = useDispatch();

  const sendMail = async (text, subject, recipient) => {
    console.log(text, subject, recipient);
    dispatch({ type: ACTIONTYPES.TOGGLE_LOADING });
    try {
      await axios.post(
        `https://us-central1-webspinner-1bc67.cloudfunctions.net/sendMail?html=${text}&subject=${subject}&to=${recipient}&account=HKSNA&cc=adam@webspinner.eu`,
        text
      );
      dispatch({ type: ACTIONTYPES.TOGGLE_LOADING });
      setSent(true);
    } catch (error) {
      console.log(error);
    }
  };

  return { sendMail, sent };
};

export default useSendEmail;
