import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./ContactFormStyles.scss";

import { TextField, Box, Button, Typography, MenuItem } from "@material-ui/core";
import Facebook from "@material-ui/icons/Facebook";

import Spinner from "../../components/Spinner/Spinner";
import useSendEmail from "../../hooks/useSendEmail";
import { EMAIL_RECIPIENTS } from "../../constants/emailRecipients";
import ReCaptcha from "../ReCaptcha/ReCaptcha";
import { getContactDestinations } from "../../contentful";

const ContactForm = ({ modal, onClose, multipleRecipients }) => {
  const { sendMail, sent } = useSendEmail();
  const { loading } = useSelector((state) => state);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const subject = `Message from ${email} `;
  const [recaptcha, setRecaptcha] = useState(false);

  const [recipients, setRecipients] = useState("secretary@hksna.org");
  useEffect(() => {
    getContactDestinations().then((data) => {
      const contactForm = data.find((data) => data.fields.place === "Contact Form");
      if (contactForm) setRecipients(contactForm?.fields.email?.join(", "));
    });
  }, []);

  const body = `<div><h4>Message from ${name}</h4><p>${email}</p><p>Tel:${phone}</p><hr/><p>${message}</p></div>`;

  const send = async () => {
    sendMail(encodeURI(body), subject, recipients);
  };

  const formComplete = () => {
    if (name && email && message && recaptcha) return true;
  };

  const inputStyle = {
    marginBottom: 16,
  };

  return (
    <div style={{ width: modal ? "100%" : "50%" }} className='contact-form'>
      {!loading && !sent && (
        <>
          {/* {multipleRecipients && recipients && (
            <TextField
              select
              label='Please select a recipient'
              variant='outlined'
              onChange={handleSetRecipient}
              style={inputStyle}
            >
              {recipients.map((recipient) => (
                <MenuItem value={recipient.address}>{recipient}</MenuItem>
              ))}
            </TextField>
          )} */}
          <TextField
            variant='outlined'
            style={inputStyle}
            fullWidth
            type='text'
            label='Full Name'
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
          <TextField
            variant='outlined'
            style={inputStyle}
            fullWidth
            type='email'
            label='Email Address'
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
          <TextField
            variant='outlined'
            style={inputStyle}
            fullWidth
            type='number'
            label='Phone Number'
            onChange={(e) => setPhone(e.target.value)}
            value={phone}
          />
          <TextField
            style={inputStyle}
            variant='outlined'
            fullWidth
            label='Message'
            multiline
            rows={8}
            onChange={(e) => setMessage(e.target.value)}
            value={message}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: 8,
            }}
          >
            <ReCaptcha onSuccess={() => setRecaptcha(true)} />
          </div>

          <Button type='button' onClick={send} variant='contained' color='primary' disabled={!formComplete()}>
            Send
          </Button>
        </>
      )}
      {loading && <Spinner />}
      {sent && (
        <div style={{ textAlign: "center" }}>
          <Typography variant='h6' color='primary' style={{ textAlign: "center" }}>
            Thanks for getting in touch! We will respond as soon as possible.
          </Typography>
          {modal && (
            <div style={{ marginTop: 35 }}>
              <Button onClick={onClose} variant='outlined' color='primary'>
                Close
              </Button>
            </div>
          )}
        </div>
      )}
      <Box className='social-container'>
        <Typography>Or find us on Facebook</Typography>
        <a
          href='https://www.facebook.com/Historical-Keyboard-Society-of-North-America-167674393408920/'
          target='_blank'
        >
          <Facebook color='primary' fontSize='large' />
        </a>
      </Box>
    </div>
  );
};

export default ContactForm;
